// Import Fuse core library
@import '@fuse/scss/core';
@import 'app/app.theme';
@import 'app/core/scss/styles';
@import 'app/core/scss/colors';

.planDialog {
    .mat-dialog-container {
        padding: 0;
    }

    .mat-dialog-content {
        overflow: visible !important;
    }

    .mat-button-toggle {
        background: #3f51b5 !important;
        color: white !important;
    }

    .mat-divider {
        color: white !important;
        background: gray !important;
    }

    .mat-dialog-actions {
        padding: 0;
        display: initial;
        flex-wrap: wrap;
        min-height: 52px;
        align-items: center;
        margin-bottom: -24px;
    }

    .mat-button {
        color: white !important;
        min-width: 0 !important;
        line-height: 46px !important;
        padding: 0 12px !important;
    }

    .mat-select-arrow {
        color: white !important;
    }

    .mat-select-value {
        color: white !important;
    }

    .mat-button-toggle-checked {
        background-color: #838bbb !important;
    }

    .button-plan {
        border-left: none;
        border-right: none;
        border-top: solid 1px rgba(0, 0, 0, 0.12);
        border: 0;
        background: none;
        color: inherit;
        padding: 0;
        margin: 0;
        font: inherit;
        outline: none;
        width: 100%;
        cursor: pointer;
        -webkit-appearance: button;
    }
}

.checklist-settings-dialog-panel {
    // use non-percent sizes, so that max-height in the children
    // has a proper reference point
    width: 60vw;
    height: 60vh;
    max-width: 60vw;
    max-height: 60vh;
}

.mat-row {
    &:hover {
        background-color: rgba(#ccc, 0.15);
    }
}

.success-snackbar {
    background-color: #007000 !important;
}

.error-snackbar {
    background-color: $error-color !important;
}

.success-snackbar,
.error-snackbar {
    color: #fff !important;
    box-shadow: 0px 1px 3px -1px rgba(255, 255, 255, 0.2),
        0px 1px 3px 0px rgba(255, 255, 255, 0.14),
        0px 1px 5px 0px rgba(255, 255, 255, 0.12) !important;

    .mat-simple-snackbar-action {
        background-color: rgba(0, 0, 0, 0.05) !important;
        color: #fff !important;
    }
}


.mat-snack-bar-container {
    margin-top: env(safe-area-inset-top) !important;
}

// fix for disappearing close button of gallery on iOS by overriding
// styles from the ngx-gallery.
//
// note: if we update ngx-gallery, this has to be tested
//
// note: we are currently keeping the status bar on Android, even
//       on notched devices, in this case `safe-area-inset-top`
//       is 0. Tested in emulator with display cutout from developer
//       settings. If we switch to full screen this has to be
//       explicitly tested again.
lightbox gallery i.g-btn-close {
    // the safe areas are set with padding in ngx-gallery originally,
    // but they break the height of the close button and therefore are
    // disabled here (for some reason, the bottom and left padding
    // were also set)
    padding: 0 !important;

    // apply the safe areas using a margin
    //
    // only the distance to the top and the right display edge
    // matters, because the button is in the top right, irrespective
    // the device orientation
    margin-top: env(safe-area-inset-top) !important;
    margin-right: env(safe-area-inset-right) !important;
    margin-bottom: 0 !important;
    margin-left: 0 !important;
}
