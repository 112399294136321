$colors: 'Draft' #fe9800, 'Open' #2b7ec4, 'Accepted' #64b9c5, 'Rejected' #909190,
    'DoneWithoutInspection' #c0c640, 'ExtendedDeadline' #f2c349,
    'FinalDeadline' #c04247, 'MailedExtendedDeadline' #f2c349,
    'MailedFinalDeadline' #c04247, 'Done' #33be68, 'Failed' #f03c3c;

@each $i in $colors {
    .issue-state {
        &-tab {
            &--#{nth($i, 1)} {
                .mat-tab-header {
                    color: #ffffff;
                    background-color: nth($i, 2);
                }
                .mat-badge-content {
                    color: nth($i, 2) !important;
                    background-color: #ffffff !important;
                }
            }
        }
        &--#{nth($i, 1)} {
            color: #ffffff;
            background-color: nth($i, 2);
        }
        &-bg {
            &--#{nth($i, 1)} {
                background-color: nth($i, 2) !important;
            }
        }
        &-fg {
            &--#{nth($i, 1)} {
                color: nth($i, 2);
            }
        }
    }
}
