// -----------------------------------------------------------------------------------------------------
// @ Custom color maps for Fuse
// -----------------------------------------------------------------------------------------------------
$fuse-white: (
    500: white,
    contrast: (
        500: $dark-primary-text
    )
);
$fuse-black: (
    500: black,
    contrast: (
        500: $light-primary-text
    )
);
$fuse-navy: (
    50: #ececee,
    100: #c5c6cb,
    200: #9ea1a9,
    300: #7d818c,
    400: #5c616f,
    500: #3c4252,
    600: #353a48,
    700: #2d323e,
    800: #262933,
    900: #1e2129,
    A100: #c5c6cb,
    A200: #9ea1a9,
    A400: #5c616f,
    A700: #2d323e,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $light-primary-text,
        400: $light-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $light-primary-text,
        A400: $light-primary-text,
        A700: $light-primary-text
    )
);
// Generate Fuse color classes for custom palettes
$custom_palettes: (
    fuse-white: $fuse-white,
    fuse-black: $fuse-black,
    fuse-navy: $fuse-navy
);
@include fuse-color-classes($custom_palettes);
// -----------------------------------------------------------------------------------------------------
// @ Typography
// -----------------------------------------------------------------------------------------------------
// Force the input field font sizes to 16px
$typography: mat-typography-config(
    $font-family: 'Roboto, Helvetica Neue, Arial, sans-serif',
    $title: mat-typography-level(20px, 32px, 500),
    $body-2: mat-typography-level(14px, 24px, 400),
    $button: mat-typography-level(14px, 14px, 400),
    $input: mat-typography-level(16px, 1.125, 400)
);
// Setup the typography
@include angular-material-typography($typography);
// -----------------------------------------------------------------------------------------------------
// @ Component theming
// -----------------------------------------------------------------------------------------------------
// Import app and page component themes here to enable theming for them
@import 'src/app/layout/components/chat-panel/chat-panel.theme';
@import 'src/app/layout/components/toolbar/toolbar.theme';
// Define a mixin for easier access
@mixin components-theme($theme) {
    // Layout components
    @include chat-panel-theme($theme);
    @include toolbar-theme($theme);
}

// -----------------------------------------------------------------------------------------------------
// @ Define the default theme
// -----------------------------------------------------------------------------------------------------
// Define the primary, accent and warn palettes
$default-primary-palette: mat-palette($fuse-navy);
$default-accent-palette: mat-palette($mat-indigo, 700);
$default-warn-palette: mat-palette($mat-red);
// Create the Material theme object
$theme: mat-light-theme(
    $default-primary-palette,
    $default-accent-palette,
    $default-warn-palette
);
// Add ".theme-default" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-default {
    .navbar-bg-color {
        background-color: #43425d;
    }
    .nav-link {
        color: #fff !important;
        mat-icon {
            color: #a5a4bf !important;
        }
        &.active {
            border-left: 5x solid #b6bcda;
            background-color: #414b61 !important;
        } // Create an Angular Material theme from the $theme map
    }
    @include angular-material-theme($theme); // Apply the theme to the Fuse Core
    @include fuse-core-theme($theme); // Apply the theme to the user components
    @include components-theme(
        $theme
    ); // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (
        primary: $default-primary-palette,
        accent: $default-accent-palette,
        warn: $default-warn-palette
    );
    @include fuse-color-classes($palettes);
}

.menu-item-order {
    padding: 0 !important;
    background-color: unset !important;
    text-align: center;
    font-weight: bold;
    margin: 12px 0;
}

.menu-item-order span {
    background: #30A159;
    padding: 12px;
    margin: 0 24px;
    border-radius: 4px;
    box-shadow: 0 1px 2px #000000;
}

.menu-item-order span:hover {
    background-color: #298A4D;
    transition: background-color 200ms cubic-bezier(0.35, 0, 0.25, 1);
}
