.speed-dial {
    &.eco-opened {
        .tooltip {
            opacity: 1;
            display: inherit;
        }
    }

    button {
        position: relative;

        .tooltip {
            transition: 0.2s ease-in;
            opacity: 0;
            // display: none;
            position: absolute;
            float: right;
            right: 50px;
            padding: 6px 12px;
            background-color: rgba(90, 90, 90, 0.9);
            font-size: 15px;
            border-radius: 5%;
            bottom: 2px;
        }
    }
}
