.mat-fab.fab-bottom-right {
    position: absolute;
    right: 10px;
    bottom: 10px;
    z-index: 3;
}

.mat-fab.fab-bottom-right-second {
    position: absolute;
    right: 10px;
    z-index: 3;
    bottom: 80px;
}

.app-input-no-underline .mat-form-field-wrapper {
    padding-bottom: 12px;
    .mat-form-field-underline {
        display: none;
    }
}

.mat-fab.fab-bottom-left {
    position: absolute;
    left: 10px;
    bottom: 10px;
    z-index: 3;
}

.mat-input-inverted {
    .mat-form-field-label {
        color: rgba(255, 255, 255, 0.6) !important;
    }
    .mat-form-field-underline {
        background-color: rgba(255, 255, 255, 0.42) !important;
    }
    .mat-form-field-ripple {
        background-color: rgba(255, 255, 255, 0.87) !important;
    }
}
