lightbox gallery {
    padding: env(safe-area-inset-top) env(safe-area-inset-right)
        env(safe-area-inset-bottom) env(safe-area-inset-left);

    .g-btn-close {
        padding: env(safe-area-inset-top) env(safe-area-inset-right)
            env(safe-area-inset-bottom) env(safe-area-inset-left);
    }
}

.material2 .nav .nav-item .nav-link {
    height: 60px !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
    border-radius: 0 !important;
}

.navbar-content .material2 .nav-item .nav-link .navbar-content {
    padding-top: 36px !important;
}

// nav items xs
fuse-sidebar.navbar-fuse-sidebar.folded:not(.unfolded)
    navbar
    navbar-vertical-style-1
    .navbar-scroll-container
    .navbar-content
    .material2
    .nav-item
    .nav-link {
    border-radius: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
}

.nav .nav-item .nav-link .nav-link-icon {
    font-size: 28px;
    margin-left: 16px;
    width: 28px;
    height: 28px;
    min-width: 28px;
    min-height: 16px;
    line-height: 28px;
}

.mat-button,
.mat-raised-button,
.mat-flat-button,
.mat-stroked-button {
    &:not(.no-uppercase) {
        text-transform: uppercase;
    }
}

.cdk-virtual-scroll-orientation-vertical .cdk-virtual-scroll-content-wrapper {
    max-width: 100%;
}

.mat-list-item {
    .mat-pseudo-checkbox {
        width: 22px !important;
        height: 22px !important;
    }
}

.mat-pseudo-checkbox {
    width: 22px !important;
    height: 22px !important;
}
