.margin-auto-y {
    margin: auto 0;
}

.margin-auto-x {
    margin: 0 auto;
}

.bottom-spacer {
    height: 60px;
    width: 100%;
}

.pt-safe-area {
    padding-top: env(safe-area-inset-top) !important;
}

.menu-item-active {
    background-color: rgba(0, 0, 0, 0.04) !important;
}

.black-bg {
    background-color: #000000;
}
